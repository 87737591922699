import moment from 'moment';

export default class DateUtils {
  static tomorrow = () => {
    const today = new Date();
    today.setDate(today.getDate() + 1);
    return today;
  };

  static yesterday = () => {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    return today;
  };

  static today = () => new Date();

  static todayToString = () => new Date().toISOString().split('T')[0];

  static displayYear = (year) => {
    if (year === '-1') {
      return 'Pre';
    }
    if (year === '0') {
      return 'R';
    }
    return year;
  };

  static isAfterToday = (date) => {
    const today = moment(DateUtils.today());
    const parsedDate = moment(date);
    return parsedDate.isAfter(today, 'day');
  };

  static isBeforeTodayOrToday = (date) => {
    const today = moment(DateUtils.today());
    const parsedDate = moment(date);
    return parsedDate.isBefore(today, 'day') || parsedDate.isSame(today, 'day');
  };

  static isTodayOrYesterday = (date) => {
    const today = moment(DateUtils.today());
    const yesterday = moment(DateUtils.yesterday());
    const parsedDate = moment(date);
    return (
      parsedDate.isSame(today, 'day') || parsedDate.isSame(yesterday, 'day')
    );
  };

  static isTodayOrAfterToday = (date) => {
    const today = moment(DateUtils.today());
    const parsedDate = moment(date);
    return parsedDate.isSame(today, 'day') || parsedDate.isAfter(today, 'day');
  };

  static formatDate = date => moment(date).format('D MMM YYYY');

  static formatDateWithDay = date => moment(date).format('ddd Do MMM YYYY');

  static formatDateWithTime = dateTime =>
    moment(dateTime).format('D MMM YYYY HH:mm');

  static isoFormatDate = date => moment(date).format('YYYY-MM-DD');

  static formatDateTimeNoSeconds = (date) => {
    if (!date) {
      return '';
    }
    return moment(date).format('YYYY-MM-DDTHH:mm');
  };

  static beginningOfWeek = (date) => {
    const parsedDate = date ? moment(date) : DateUtils.today();
    // moment's beginning of week is Sunday so add one day
    return moment(parsedDate)
      .startOf('week')
      .add(1, 'days');
  };

  static fromStringToDate = str =>
    str.replace(/(\d{2}),(\d{2}),(\d{4})/, '$2/$1/$3');

  static getDatesInRange = (start, finish, daySlot) => {
    const dates = [];
    let currentDate = moment(start).startOf('day');
    const closestDaySlot = moment(start)
      .day(daySlot)
      .startOf('day');

    // the start of the given range may not fall on the dayslot
    // of the timetable. If this is the case we need to adjust the
    // currentDate to match the next day slot.
    if (closestDaySlot.day() < currentDate.day()) {
      currentDate = closestDaySlot.add(7, 'days');
    }

    currentDate = closestDaySlot;

    while (currentDate <= moment(finish)) {
      dates.push(this.isoFormatDate(currentDate));
      currentDate = currentDate.add(7, 'days');
    }
    return dates;
  };

  // Expects month numbers NOT month indices
  static dateIsBetweenMonths = (
    firstMonth,
    secondMonth,
    date = DateUtils.today(),
  ) => date.getMonth() > firstMonth - 1 && date.getMonth() < secondMonth - 1;

  static secondsToMinutesRounded = seconds => Math.round(seconds / 60);
}
