import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import React from 'react';

// eslint-disable-next-line import/no-mutable-exports
let BugsnagClient;

if (process.env.NODE_ENV === 'test') {
  BugsnagClient = {
    notify: () => {},
  };
} else {
  const elCurrentUserData = document.getElementById('current_user_data');
  const currentUserData = {
    id: elCurrentUserData?.getAttribute('data-user-id'),
  };
  const bugsnagApiKey = document.getElementById('bugsnag_api_key').getAttribute('data');
  BugsnagClient = bugsnag({
    apiKey: bugsnagApiKey,
  });
  BugsnagClient.use(bugsnagReact, React);
  BugsnagClient.user = {
    id: currentUserData.id || 0,
  };
  BugsnagClient.metaData = {
    props: null,
  };
  BugsnagClient.startSession();
}

export default BugsnagClient;
